import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function Tangled() {
  return (
    <Layout>
      <Helmet title="Tangled | Maroon 5" />

      <h1>Tangled</h1>

      <h2>Maroon 5</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>I'm full of regret</p>
          <p>For all things that I have done and said</p>
          <p>And I don't know if it'll ever be okay to show</p>
          <p>My face 'round here</p>
          <p>Sometimes I wonder if I disappear</p>
        </Verse>
        <Chorus>
          <p>Would you ever turn your head and look</p>
          <p>See if I'm gone</p>
          <p>'Cause I fear</p>
          <p>There is nothing left to say</p>
          <p>To you</p>
          <p>That you wanna hear</p>
          <p>That you wanna know</p>
          <p>I think I should go</p>
          <p>The things I've done are way too shameful</p>
        </Chorus>
        <Verse>
          <p>You're just innocent</p>
          <p>A helpless victim of a spider's web</p>
          <p>And I'm an insect</p>
          <p>Goin' after anything that I can get</p>
        </Verse>
        <Chorus>
          <p>So you better turn your head and run</p>
          <p>And don't look back</p>
          <p>'Cause I fear</p>
          <p>There is nothing left to say</p>
          <p>To you</p>
          <p>That you wanna hear</p>
          <p>That you wanna know</p>
          <p>I think I should go</p>
          <p>The things I've done are way too shameful</p>
        </Chorus>
        <Bridge>
          <p>And I've done you so wrong</p>
          <p>Treated you bad</p>
          <p>Strung you along</p>
          <p>Oh shame on myself</p>
          <p>I don't know how I got so tangled</p>
        </Bridge>
        <Bridge>
          <p>Mmmm, oh, yeah, yeah, yeah! Yeah! Yeah-yeah-yeah!</p>
        </Bridge>
        <Chorus>
          <p>Would you ever turn your head and look</p>
          <p>See if I'm gone</p>
          <p>'Cause I fear</p>
          <p>There is nothing left to say</p>
          <p>To you</p>
          <p>That you wanna hear</p>
          <p>That you wanna know</p>
          <p>I think I should go</p>
          <p>The things I've done are way too shameful</p>
        </Chorus>
        <Bridge>
          <p>And I've done you so wrong</p>
          <p>Treated you bad</p>
          <p>Strung you along</p>
          <p>Oh shame on myself</p>
          <p>I don't know how I got so tangled</p>
        </Bridge>
        <Bridge>
          <p>And I've done you so wrong</p>
          <p>Treated you bad</p>
          <p>Strung you along</p>
          <p>Oh shame on myself</p>
          <p>I don't know how I got so tangled</p>
        </Bridge>
      </Columns>
    </Layout>
  );
}
